import React from 'react';

import { Popover as HeadlessPopover, Transition } from '@headlessui/react';
import clsx from 'clsx';

export default function Popover({
  content,
  children,
  autoWidth = false,
  cropHeight = false,
}) {
  return (
    <HeadlessPopover className="relative">
      <HeadlessPopover.Button>{children}</HeadlessPopover.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <HeadlessPopover.Panel
          className={clsx(
            'absolute z-10 mt-2 bg-white text-black font-normal shadow-lg rounded-md p-4 overflow-auto sm:text-sm border',
            cropHeight && 'max-h-60',
            autoWidth ? 'w-full' : 'w-80'
          )}
        >
          {content}
        </HeadlessPopover.Panel>
      </Transition>
    </HeadlessPopover>
  );
}
