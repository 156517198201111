import React from 'react';

import clsx from 'clsx';
import useUser from 'ctx/useAuth';
import useCompany from 'hooks/useCompany';
import useDateRangePresets from 'hooks/useDateRangePresets';
import useHybridWorkStatistics from 'hooks/useHybridWorkStatistics';
import CarIcon from 'images/solid/car.svg';
import PieChartIcon from 'images/solid/chart-pie.svg';
import ClockIcon from 'images/solid/clock.svg';
import DollarIcon from 'images/solid/dollar.svg';
import OfficeIcon from 'images/solid/office.svg';
import UserIcon from 'images/solid/user.svg';
import Layout from 'layouts/Home';

import BookingActivity from 'components/BookingActivityCard';
import DateRangePresets from 'components/DateRangePresets';
import { formatNumber } from 'components/Format';
import MetricCard from 'components/MetricCard';
import PageShell from 'components/PageShell';
import Popover from 'components/Popover';
import RankingCard from 'components/RankingCard';

const STUDY_SOURCE_URL =
  'https://globalworkplaceanalytics.com/wp-content/uploads/edd/2021/01/The-Business-Case-for-Remote-Work-2021-Report-Final-Web-1.pdf';

const Table = ({ data = [] }) => (
  <ul className="text-sm text-black -my-2.5 px-2 py-1">
    {data.map(({ label, value }, idx) => {
      const isLast = data.length === idx + 1;
      return (
        <li
          key={idx}
          className={clsx(
            'flex py-2.5 space-x-2',
            idx > 0 && 'border-t',
            isLast ? 'border-gray-250' : 'border-gray-100'
          )}
        >
          <span className={clsx('grow', isLast && 'font-bold')}>{label}</span>
          <span className={clsx('shrink-0', isLast && 'font-bold text-spoto')}>
            {value}
          </span>
        </li>
      );
    })}
  </ul>
);

const Footnote = ({ children }) => (
  <div className="p-4 text-xs bg-gray-50 text-gray-500 border border-gray-100 rounded space-y-4">
    {children}
  </div>
);

const PopoverContent = {
  ProductiveTime: ({ workingDays, total }) => (
    <div className="space-y-4">
      <Table
        data={[
          { label: 'Interruptions avoided¹', value: '35min' },
          { label: 'Worked instead of commute²', value: '26min' },
          { label: 'Working days', value: workingDays },
          { label: 'Total time gain', value: total },
        ]}
      />
      <Footnote>
        <p>
          (1) Employees loose 35 min a day less while working from home vs. the
          office.{' '}
          <a
            href={STUDY_SOURCE_URL}
            className="underline"
            rel="noreferrer"
            target="_blank"
          >
            Source
          </a>
        </p>
        <p>
          (2) Employees voluntairly work 47% of the saved commute time (26 min a
          day on average).{' '}
          <a
            href={STUDY_SOURCE_URL}
            className="underline"
            rel="noreferrer"
            target="_blank"
          >
            Source
          </a>
        </p>
      </Footnote>
    </div>
  ),
  CompanySavings: ({ workingDays, total }) => (
    <div className="space-y-4">
      <Table
        data={[
          { label: 'Saved per day¹', value: '$42' },
          { label: 'Working days', value: workingDays },
          { label: 'Total money saved', value: total },
        ]}
      />
      <Footnote>
        (1) Companies save $11,000 per semi/remote employee per year (or
        $42/day).{' '}
        <a
          href={STUDY_SOURCE_URL}
          className="underline"
          rel="noreferrer"
          target="_blank"
        >
          Source
        </a>
      </Footnote>
    </div>
  ),
  CommuteSaved: ({ workingDays, total }) => (
    <div className="space-y-4">
      <Table
        data={[
          { label: 'Commute saved per day¹', value: '55min' },
          { label: 'Working days', value: workingDays },
          { label: 'Total commute saved', value: total },
        ]}
      />
      <Footnote>
        (1) Average round-trip commute is 55min/day.{' '}
        <a
          href={STUDY_SOURCE_URL}
          className="underline"
          rel="noreferrer"
          target="_blank"
        >
          Source
        </a>
      </Footnote>
    </div>
  ),
  TalentRetention: () => (
    <Footnote>
      <p>
        81% of employees want the hybrid/fully remote work model.{' '}
        <a
          href={STUDY_SOURCE_URL}
          className="underline"
          rel="noreferrer"
          target="_blank"
        >
          Source
        </a>
      </p>
      <p>
        48% will consider quitting if there will be no flexible work policy.{' '}
        <a
          href={STUDY_SOURCE_URL}
          className="underline"
          rel="noreferrer"
          target="_blank"
        >
          Source
        </a>
      </p>
      <p>
        32% will quit.{' '}
        <a
          href={STUDY_SOURCE_URL}
          className="underline"
          rel="noreferrer"
          target="_blank"
        >
          Source
        </a>
      </p>
    </Footnote>
  ),
};

function Page() {
  useUser();

  const [selectedDateRangePresetValue, setDateRangePresetValue] =
    React.useState(undefined);

  const { data: dateRangePresets, fetching: fetchingDateRange } =
    useDateRangePresets();

  const { data: company, fetching: fetchingEmployee } = useCompany({
    datePreset: selectedDateRangePresetValue,
    pause: !selectedDateRangePresetValue,
  });

  const fetching = fetchingDateRange || fetchingEmployee;

  React.useEffect(() => {
    if (dateRangePresets.length > 0) {
      setDateRangePresetValue(dateRangePresets.at(0).value);
    }
  }, [dateRangePresets]);

  const {
    averageBookingLength = 0,
    workingDays = 0,
    topHosts = [],
    topUsers = [],
    bookingActivity,
  } = company;

  const { productiveTime, companySavings, commuteTimeSavings } =
    useHybridWorkStatistics(workingDays);

  const companyMetrics = React.useMemo(
    () => [
      {
        iconComponent: ClockIcon,
        label: 'Productive time',
        value: (
          <Popover
            content={
              <PopoverContent.ProductiveTime
                workingDays={workingDays}
                total={productiveTime}
              />
            }
          >
            <span className="text-spoto font-semibold border-b border-dashed border-spoto">
              {productiveTime}
            </span>
          </Popover>
        ),
      },
      {
        iconComponent: DollarIcon,
        label: 'Company savings',
        value: (
          <Popover
            content={
              <PopoverContent.CompanySavings
                workingDays={workingDays}
                total={companySavings}
              />
            }
          >
            <span className="text-spoto font-semibold border-b border-dashed border-spoto">
              {companySavings}
            </span>
          </Popover>
        ),
      },
      {
        iconComponent: CarIcon,
        label: 'Commute saved',
        value: (
          <Popover
            content={
              <PopoverContent.CommuteSaved
                workingDays={workingDays}
                total={commuteTimeSavings}
              />
            }
          >
            <span className="text-spoto font-semibold border-b border-dashed border-spoto">
              {commuteTimeSavings}
            </span>
          </Popover>
        ),
      },
      {
        iconComponent: UserIcon,
        label: 'Talent retention',
        value: (
          <Popover content={<PopoverContent.TalentRetention />}>
            <span className="text-spoto font-semibold border-b border-dashed border-spoto">
              Improved
            </span>
          </Popover>
        ),
      },
    ],
    [productiveTime, companySavings, commuteTimeSavings, workingDays]
  );

  return (
    <PageShell>
      <PageShell.Header title="Dashboard">
        <DateRangePresets
          selectedPresetValue={selectedDateRangePresetValue}
          onChange={setDateRangePresetValue}
        />
      </PageShell.Header>

      <PageShell.Body>
        <div>
          <h3 className="text-xs text-gray-500 font-medium uppercase">
            Hybrid Work Statistics
          </h3>
          <dl className="mt-4 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
            {companyMetrics.map(({ iconComponent, label, value }) => (
              <MetricCard
                key={label}
                iconComponent={iconComponent}
                label={label}
                value={value}
                fetching={fetching}
              />
            ))}
          </dl>
        </div>

        <div>
          <h3 className="text-xs text-gray-500 font-medium uppercase">
            Spoto Activity
          </h3>

          <div className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-5">
            <div className="lg:col-span-3">
              <BookingActivity
                showBookingsPerEmployee
                bookingActivity={bookingActivity}
                dateRangePresetValue={selectedDateRangePresetValue}
              />
            </div>

            <RankingCard
              title="Usage"
              fetching={fetching}
              iconComponent={PieChartIcon}
              noResultsMessage="Usage statistics will appear here after the first booking."
              data={
                averageBookingLength
                  ? [
                      {
                        label: 'Average booking length',
                        value: averageBookingLength,
                      },
                    ]
                  : []
              }
            />

            <RankingCard
              title="Top hosts"
              fetching={fetching}
              iconComponent={OfficeIcon}
              noResultsMessage="Top hosts will appear here after the first booking."
              data={topHosts.map(({ bookingsCount, name, city }) => ({
                label: `${name}, ${city}`,
                value: bookingsCount,
              }))}
            />

            <RankingCard
              title="Top users, bookings"
              fetching={fetching}
              iconComponent={UserIcon}
              noResultsMessage="Users who make most bookings will appear here after the first booking."
              data={topUsers.map(({ bookingsCount, firstName, lastName }) => ({
                label: `${firstName} ${lastName}`,
                value: formatNumber(bookingsCount),
              }))}
            />
          </div>
        </div>
      </PageShell.Body>
    </PageShell>
  );
}

Page.getLayout = (page) => {
  return <Layout>{page}</Layout>;
};

export default Page;
