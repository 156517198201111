import React from 'react';

import dayjs from 'dayjs';

import { formatCurrency } from 'components/Format';

export default function useHybridWorkStatistics(workingDays = 0) {
  const productiveTime = React.useMemo(() => {
    const INTERRUPTIONS_AVOIDED = 35; // in minutes
    const WORK_INSTEAD_COMMUTE = 26; // in minutes

    const totalTimeGain = dayjs
      .duration(
        INTERRUPTIONS_AVOIDED * WORK_INSTEAD_COMMUTE * workingDays * 60 * 1000
      )
      .format('H[h] m[m]');

    return `+${totalTimeGain}`;
  }, [workingDays]);

  const companySavings = React.useMemo(() => {
    const VALUE_SAVED_PER_DAY = 45; // in USD

    const totalSavings = formatCurrency(VALUE_SAVED_PER_DAY * workingDays);

    return totalSavings;
  }, [workingDays]);

  const commuteTimeSavings = React.useMemo(() => {
    const COMMUTE_TIME_SAVED_PER_DAY = 55; // in minutes

    const totalSavings = dayjs
      .duration(COMMUTE_TIME_SAVED_PER_DAY * workingDays * 60 * 1000)
      .format('H[h] m[m]');

    return totalSavings;
  }, [workingDays]);

  return {
    productiveTime,
    companySavings,
    commuteTimeSavings,
  };
}
