import React from 'react';

import { CompanyQuery } from 'queries';
import { useQuery } from 'urql';

export default function useCompany({ pause, ...variables }) {
  const [{ data, ...result }] = useQuery({
    query: CompanyQuery,
    variables,
    pause,
  });

  const company = React.useMemo(() => data?.company || {}, [data]);

  return {
    ...result,
    data: company,
  };
}
